import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/sections/bio_details";
import { Hook } from "../../Services/Default";

export default class Section extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.fetchData();
  }

  async fetchData() {
    const { hook } = this.props;
    const lang = this.getHelpers("language").getLang();

    if (hook) {
      const response = await Hook.get(hook, {
        params: {
          lang,
        },
      });

      if (response.isValid()) {
        const contents = response.getData();

        this.setData(
          { contents },
          {
            cb: () => {
              this.getPage().acknowledge();
            },
          }
        );
      }
    } else {
      this.getPage().acknowledge();
    }
  }
}
