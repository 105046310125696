export default function Template(props) {
  return (
    <>
      <div className="our-party-section-area party-vision">
        <div
          className="party-bg vision-bg"
          style={{ backgroundImage: "url(/assets/img/group-confident.png)" }}
        ></div>
        <img
          src="assets/img/line-shape.png"
          className="party-vision-shape"
          alt=""
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="party-single-item vision">
                <div className="content">
                  <div className="subtitle wow animate__animated animate__fadeInUp">
                    <p>Our Party History</p>
                    <div className="icon">
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                      <i className="icon-star"></i>
                    </div>
                  </div>
                  <h4 className="title wow animate__animated animate__fadeInUp">
                    Join The Fight for Our Freedom
                  </h4>
                  <p className="description wow animate__animated animate__fadeInUp">
                    Every pleasures is to welcomed pain avoided owing to the
                    duty the obligations of business it will frequently occur
                    that pleasures have to.
                  </p>
                  <p className="description none wow animate__animated animate__fadeInUp">
                    How all this mistaken idea of denouncing pleasure and
                    praising pain was born & we will give you a complete account
                    of the system.
                  </p>
                  <div className="vision-quotes wow animate__animated animate__fadeInRight animate__delay-1s">
                    <h5 className="title">
                      Successfully Providing the Best Solution from 20 years
                    </h5>
                    <div className="icon">
                      <img src="assets/icon/quotes-02.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="btn-wrapper margin-top-35 wow animate__animated animate__fadeInUp">
                  <a
                    href="history.html"
                    className="boxed-btn btn-sanatory style-02"
                  >
                    <i className="fas fa-arrow-right"></i>Read More Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
