export default function Template(props) {
  const { component } = props;
  const { images = [], title = "" } = component.getData() || {};

  return (
    <div
      className="political-gallery-item style-01"
      style={{ marginTop: "220px" }}
    >
      {title && (
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="section-title">
                <h4 class="title style-02 wow animate__animated animate__fadeInUp">
                  {title}
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="testimonial-carousel-seven">
            {images.map((image, index) => {
              return (
                <div
                  key={`gallery-${index}`}
                  className="gallery-single-items wow animate__ animate__fadeInUp animated"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <div className="thumb style-01">
                    <div
                      style={{
                        width: 300,
                        height: 300,
                        background: `url(${image})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        margin: "0 auto",
                      }}
                    ></div>
                    <div className="cart-icon">
                      <a className="image-popup" href={image}>
                        <i className="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
