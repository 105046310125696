export default function Template(props) {
  const { component } = props;

  return (
    <div
      className="container"
      style={{ textAlign: "center", marginTop: "50px" }}
    >
      <div className="section-title" style={{ marginBottom: 0 }}>
        <h3 className=" title" style={{ marginBottom: 0 }}>
          Η κοινοβουλευτική δράση της Φωτεινής Πιπιλή
        </h3>
      </div>

      <a
        href={component
          .getApp()
          .getFilesUrl(
            `https://files.automatapp.com/pipili/assets/pdf/parliamentary-action.pdf`
          )}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={component
            .getApp()
            .getFilesUrl(
              `https://files.automatapp.com/pipili/assets/images/pdf.png`
            )}
          style={{ height: "100px" }}
          alt="biography"
        />
      </a>
    </div>
  );
}
