import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/sections/vision_party";

export default class Section extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.getPage().acknowledge();
  }
}
