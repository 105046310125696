export default function Template(props) {
  return (
    <div className="administration-section home-six">
      <div className="container custom-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="administration-single-items style-01 about-two">
              <div className="content">
                <h4 className="title wow animate__animated animate__fadeInUp">
                  We Have Super Power <br />
                  Administration
                </h4>
                <p className="wow animate__animated animate__fadeInUp">
                  Every pleasures is to welcomed pain avoided owing to the duty
                  the obligations of business it will frequently occur that
                  pleasures have to be repudiated annoyances accepted.
                </p>
              </div>
              <div className="icon-box-wrap">
                <div className="icon-box">
                  <div className="icon">
                    <i className="flaticon-comment"></i>
                  </div>
                  <div className="content">
                    <p>
                      99.8% Positive <br />
                      Feedback
                    </p>
                  </div>
                </div>
                <div className="icon-box">
                  <div className="icon">
                    <i className="flaticon-add-user"></i>
                  </div>
                  <div className="content">
                    <p>
                      103 Board Member <br />
                      of Senatory
                    </p>
                  </div>
                </div>
              </div>
              <div className="list-items wow animate__animated animate__fadeInUp">
                <ul>
                  <li>Politics is the set of activities that are associated</li>
                  <li>There are several ways in which approaching politics.</li>
                  <li>Some perspectives on politics view it empirically.</li>
                </ul>
              </div>
              <div className="campaigns-no">
                <div className="content">
                  <h3 className="number">29,000+</h3>
                  <p>Successfull Campaigns</p>
                </div>
                <div className="thumbnail">
                  <img src="assets/img/home-six/signiture.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="administration-single-items about-two">
              <div
                className="administrative-bg"
                style={{
                  backgroundImage: "url(/assets/img/business-people.png)",
                }}
              >
                <div className="btn-wrapper administration">
                  <a
                    className="video-popup mfp-iframe"
                    href="https://www.youtube.com/watch?v=-ZwQtICNbRc"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                  <p>Watch Our Video</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
