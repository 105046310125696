import Menu from "./header/menu";
import React, { useEffect, useState } from "react";

let sliderIndex = 0;

export default function Template(props) {
  const { component } = props;
  const [showSliderIndex, setShowSliderIndex] = useState(0);
  const [imagesFolder, setImagesFolder] = useState("desktop");
  const transition = 7000;
  const { contents = [] } = component.getData() || {};

  sliderIndex = showSliderIndex;

  const goNextSlider = () => {
    setShowSliderIndex((sliderIndex + 1) % contents.length);
  };

  const goPreSlider = () => {
    sliderIndex -= 1;

    if (sliderIndex < 0) {
      sliderIndex = contents.length - 1;
    }

    setShowSliderIndex(sliderIndex);
  };

  useEffect(() => {
    if (contents.length > 1) {
      setInterval(() => {
        goNextSlider();
      }, transition);
    }

    const resize = () => {
      if (window.innerWidth <= 990) {
        setImagesFolder("mobile");
      } else {
        setImagesFolder("desktop");
      }
    };

    window.addEventListener("resize", resize);

    resize();
  }, [contents.length]);

  return (
    <>
      <div className="header1 header-style-01">
        <nav className="navbar navbar-area style-01 navbar-expand-lg nav-style-02">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo-wrapper">
                <a href="/" className="logo">
                  <img
                    src={component
                      .getApp()
                      .getFilesUrl(
                        "https://files.automatapp.com/pipili/assets/images/logo.png"
                      )}
                    alt=""
                  />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#bizcoxx_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <Menu />
          </div>
        </nav>
      </div>

      {contents.map(({ subtitle }, index) => {
        const id = `slider-${index}`;

        return (
          <div
            id={id}
            key={id}
            className="header-area header-sanatory header-bg-02"
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              zIndex: showSliderIndex === index ? 1 : -1,
              opacity: showSliderIndex === index ? 1 : 0,
              transition: "opacity 0.8s ease-in-out",
              backgroundImage: `url("${component
                .getApp()
                .getFilesUrl(
                  `https://files.automatapp.com/pipili/assets/images/home/${imagesFolder}/${
                    index + 1
                  }.jpg`
                )}")`,
              height: "100vh",
              width: "100vw",
            }}
          >
            <div className="container nav-container">
              <div className="row">
                <div className="col-lg-9">
                  <div className="header-inner political">
                    <div className="subtitle">
                      <span>ΦΩΤΕΙΝΗ ΠΙΠΙΛΗ</span>
                      <span className="line dark-blue"></span>
                      <span className="line-02 dark-blue"></span>
                    </div>
                    <h5
                      className="subtitle-01"
                      style={{
                        display: "flex",
                        color: "white",
                        marginBottom: "20px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          height: 60,
                          width: 60,
                          background: "white",
                          borderRadius: "50%",
                          marginRight: "10px",
                          backgroundImage: `url(${component
                            .getApp()
                            .getFilesUrl(
                              `https://files.automatapp.com/pipili/assets/images/nd-logo.svg`
                            )})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          backgroundSize: "75% 75%",
                          display: "inline-block",
                        }}
                        alt="nea dimokratia"
                      ></div>
                      <span>{component.trans("subtitle")}</span>
                    </h5>
                    <h1
                      className="title"
                      style={{
                        color: "white",
                        marginBottom: "20px",
                        textTransform: "capitalize",
                        fontSize: "2.5rem",
                        lineHeight: 1.2,
                      }}
                    >
                      {subtitle}
                    </h1>

                    <div className="top-social">
                      <ul className="header-social-share">
                        <li>
                          <a
                            href="https://www.facebook.com/fotini.pipili"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/fotinipipili/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/FotiniPipili"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="main-slider owl-prev"
        onClick={() => {
          goPreSlider();
        }}
      >
        <i className="fa fa-angle-left"></i>
      </div>
      <div
        className="main-slider owl-next"
        onClick={() => {
          goNextSlider();
        }}
      >
        <i className="fa fa-angle-right"></i>
      </div>
    </>
  );
}
