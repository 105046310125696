import CorePage from "../../modules/Core/Components/Page";
import Sections from "./Sections";
import { Hook } from "../Services/Default";

export default class Page extends CorePage {
  sectionsAmount = 0;
  sectionsLoaded = 0;

  template = (props) => {
    const { component } = props;

    const page = component.getData("page") || {};
    const { sections = [] } = page;

    return sections.map(({ name, hook, enabled }) => {
      const Component = Sections[name];
      const active = Component && enabled ? true : false;

      if (!active) {
        this.sectionsAmount -= 1;
      }

      return active ? (
        <Component key={`page-section-${name}`} {...{ hook }} />
      ) : (
        ""
      );
    });
  };

  onLoad(data) {
    super.onLoad(data);
    this.fetchPage();
  }

  async fetchPage() {
    const path = window.location.pathname;
    const response = await Hook.get("page", {
      params: {
        path,
      },
    });

    const { _values = {} } = response.getData() || {};
    const { sections = [] } = _values;

    this.sectionsAmount = sections.length;

    this.setData({ page: _values });
    this.loadJavascripts(_values);
  }

  loadJavascripts({ javascripts = [] }) {
    const files = javascripts.map(({ path }) => {
      return path;
    });

    files.forEach((file) => {
      this.loadJavascript(file);
    });

    return this;
  }

  loadJavascript(path) {
    let script = document.createElement("script");
    script.src = `${path}.js`;
    script.async = false;
    document.body.appendChild(script);
    return this;
  }

  loadMainJs() {
    this.loadJavascript("/assets/js/main");
    return this;
  }

  acknowledge() {
    this.sectionsLoaded += 1;

    if (this.sectionsLoaded >= this.sectionsAmount) {
      this.loadMainJs();
    }
  }
}
