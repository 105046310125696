export default function Template(props) {
  return (
    <div className="collapse navbar-collapse" id="bizcoxx_main_menu">
      <ul className="navbar-nav political">
        {/* <li className="menu-item-has-children current-menu-item"> */}
        <li className="menu-item-has-children ">
          <a href="/">Ποιά είμαι</a>
          <ul className="sub-menu">
            <li>
              <a href="/bio.html">Βιογραφικό</a>
            </li>
            <li>
              <li>
                <a href="/parliamentary-action.html">Κοινοβουλευτική δράση</a>
              </li>
              {/* <a
                href="https://files.automatapp.com/pipili/assets/pdf/parliamentary-action.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Κοινοβουλευτική δράση
              </a> */}
            </li>
            <li>
              <a href="/sketches.html">σκίτσα</a>
            </li>
            {/* <li>
              <a href="/about.html">Είπαν για εμένα</a>
            </li> */}
            {/* <li>
              <a href="/">Ατάκες</a>
            </li>
            <li>
              <a href="/">Πιπιλή – Χρυσή Αυγή</a>
            </li>
            <li>
              <a href="/">Επικοινωνία</a>
            </li> */}
          </ul>
          <div className="line style-01">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot style-02"></span>
          </div>
        </li>
        <li className="menu-item-has-children">
          <a href="#">Κοινοβουλευτισμός</a>
          <ul className="sub-menu">
            {/* <li>
              <a href="/koinobouleytismos-2007-2009.html">2007 - 2009</a>
            </li>
            <li>
              <a href="/">2009 - 2012</a>
            </li>
            <li>
              <a href="/">2012 - 2015</a>
            </li> */}
            <li>
              <a href="/koinobouleytismos-2019-today.html">2019 - Σήμερα</a>
            </li>
            {/* <li>
              <a href="/">ΣτΕ - Αγία Σοφία</a>
            </li> */}
          </ul>
          <div className="line">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot style-02"></span>
          </div>
        </li>
        {/* <li className="menu-item-has-children">
          <a href="#">Πολιτισμός της Καθημερινότητας</a>
          <ul className="sub-menu">
            <li>
              <a href="/">Η Αθήνα μου</a>
            </li>
            <li>
              <a href="/">Πολιτισμός</a>
            </li>
            <li>
              <a href="/">Διατηρητέα - Νεοκλασικά</a>
            </li>
            <li>
              <a href="/">Φιλοζωία</a>
            </li>
            <li>
              <a href="/">LGBTQ</a>
            </li>
          </ul>
          <div className="line">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot style-02"></span>
          </div>
        </li>
        <li className="menu-item-has-children">
          <a href="#">ΜΜΕ</a>
          <ul className="sub-menu">
            <li>
              <a href="/">Τηλεόραση</a>
            </li>
            <li>
              <a href="/">Ραδιόφωνο</a>
            </li>
            <li>
              <a href="/">Άρθρα - Συνεντεύξεις</a>
            </li>
            <li>
              <a href="/">Δελτία Τύπου</a>
            </li>
          </ul>
          <div className="line">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot style-02"></span>
          </div>
        </li>
        <li className="menu-item-has-children">
          <a href="#">Η ιστορία μου</a>
          <ul className="sub-menu">
            <li>
              <a href="/">Η ιστορία μου - Οικογενειακή κατάσταση</a>
            </li>
            <li>
              <a href="/">Δημοσιογραφική καριέρα</a>
            </li>
            <li>
              <a href="/">Πρώτη γυναίκα Ρεπόρτερ</a>
            </li>
            <li>
              <a href="/">Πάνθεον</a>
            </li>
            <li>
              <a href="/">ΕΡΤ</a>
            </li>
            <li>
              <a href="/">ΑΝΤ1</a>
            </li>
            <li>
              <a href="/">MEGA</a>
            </li>
            <li>
              <a href="/">Κριτικές για μένα</a>
            </li>
            <li>
              <a href="/">Δήμος Αθηναίων</a>
            </li>
            <li>
              <a href="/">Υποψήφια Νομάρχης Αθηνών</a>
            </li>
            <li>
              <a href="/">Βουλευτής 2007-2014</a>
            </li>
            <li>
              <a href="/">Βουλευτής 2019</a>
            </li>
            <li>
              <a href="/">Βιβλία - Φωτογραφίες</a>
            </li>
            <li>
              <a href="/">Βραβεία</a>
            </li>
            <li>
              <a href="/">Ταξίδια</a>
            </li>
            <li>
              <a href="/">Περιβαλλοντικά - Φιλοζωία</a>
            </li>
            <li>
              <a href="/">Καθαρές ακτές</a>
            </li>
          </ul>
          <div className="line">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot style-02"></span>
          </div>
        </li>
        <li className="menu-item-has-children">
          <a href="/">News</a>
          <ul className="sub-menu">
            <li>
              <a href="/news.html">News</a>
            </li>
            <li>
              <a href="/">News Two</a>
            </li>
            <li>
              <a href="/">News Three</a>
            </li>
            <li>
              <a href="/">News Four</a>
            </li>
            <li>
              <a href="/">News Single</a>
            </li>
          </ul>
          <div className="line style-01">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot style-02"></span>
          </div>
        </li> */}

        {/* <li className="menu-item-has-children">
                    <a href="#0">Ατάκες</a>
                  </li> */}
        {/* <li className="menu-item-has-children">
                    <a href="#0">Πιπιλή – Χρυσή Αυγή</a>
                  </li> */}
        {/* <li className="menu-item-has-children">
                    <a href="#0">Επικοινωνία</a>
                  </li> */}
      </ul>
    </div>
  );
}
