export default function Template(props) {
  return (
    <div className="header-bottom-area margin-top-120">
      <div className="container">
        <div className="political-header-bottom m-top-02">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="our-vision-item wow animate__animated animate__fadeInLeft">
                <div
                  className="vision-bg"
                  style={{ backgroundImage: "url(/assets/img/rally.png)" }}
                >
                  <div className="content">
                    <div className="subtitle">
                      <p>Our Party Visions</p>
                      <div className="icon">
                        <i className="icon-star"></i>
                        <i className="icon-star"></i>
                        <i className="icon-star"></i>
                      </div>
                    </div>
                    <h4 className="title">Together We Are Stronger</h4>
                    <div className="btn-wrapper">
                      <a
                        href="about.html"
                        className="boxed-btn btn-sanatory style-01 reverse"
                      >
                        <i className="fas fa-arrow-right"></i>View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="vision-single-item-wrapper">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="our-vision-single-item  wow animate__animated animate__fadeInUp">
                      <div className="icon">
                        <i className="icon-winner-cup"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">We Will Win The Elections</h4>
                        <p>
                          Phasellus iaculis posuere velit, congue placerat duawi
                          rhoncus vel. Maecenas tortor orci, aliquet.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="our-vision-single-item style-01 wow animate__animated animate__fadeInRight">
                      <div className="icon">
                        <i className="icon-love-4223"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">We Care About Causes</h4>
                        <p>
                          Phasellus iaculis posuere velit, congue placerat duawi
                          rhoncus vel. Maecenas tortor orci, aliquet.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="our-vision-single-item style-02 wow animate__animated animate__fadeInUp animate__delay-1s">
                      <div className="icon">
                        <i className="icon-tree"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">Our Party Is Growing</h4>
                        <p>
                          Phasellus iaculis posuere velit, congue placerat duawi
                          rhoncus vel. Maecenas tortor orci, aliquet.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="our-vision-single-item wow animate__animated animate__fadeInRight animate__delay-1s">
                      <div className="icon">
                        <i className="icon-target-3696"></i>
                      </div>
                      <div className="content">
                        <h4 className="title">Get Involved</h4>
                        <p>
                          Phasellus iaculis posuere velit, congue placerat duawi
                          rhoncus vel. Maecenas tortor orci, aliquet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
