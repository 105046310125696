import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/sections/photo_gallery";
import { Hook } from "../../Services/Default";
import axios from "axios";

export default class Section extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    const { hook } = this.props;

    if (hook) {
      this.fetchData(hook);
    } else {
      this.getPage().acknowledge();
    }
  }

  async fetchData(hook) {
    const response = await Hook.get(hook);
    const article = response.getData();
    const images = await this.fetchImages(article);
    const title = this.getTitle(article);

    this.setData(
      { images, title },
      {
        cb: () => {
          this.getPage().acknowledge();
        },
      }
    );
  }

  getTitle(article) {
    const { _values } = article;
    const { translations = [] } = _values;
    const translation = translations.shift();

    return translation ? translation.translation : "";
  }

  async fetchImages(article) {
    const { _values } = article;
    const { slug } = _values;

    const images = await axios.get(
      `https://files.automatapp.com/listing/pipili/articles/${slug}/images`
    );

    const { data = [] } = images || {};

    return data.map((file) => {
      return `https://files.automatapp.com/pipili/articles/${slug}/images/${file}`;
    });
  }
}
