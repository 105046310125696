import Header1 from "./Header1";
import Header2 from "./Header2";
import VisionParty from "./VisionParty";
import HeaderBottom from "./HeaderBottom";
import JourJourney from "./JourJourney";
import Videos from "./Videos";
import Timeline from "./Timeline";
import Administration from "./Administration";
import PhotoGallery from "./PhotoGallery";
// import OurVideo from "./OurVideo";
import News from "./News";
import BioDetails from "./BioDetails";
import BioLink from "./BioLink";
import Footer1 from "./Footer1";

const Exports = {
  Header1,
  Header2,
  VisionParty,
  HeaderBottom,
  JourJourney,
  Videos,
  Timeline,
  Administration,
  PhotoGallery,
  // OurVideo,
  News,
  BioDetails,
  BioLink,
  Footer1,
};

export default Exports;
