import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <Switch>
          <Route component={Pages.Page} />
        </Switch>
      </Router>
    )
  );
};

export default ExportDefault;
