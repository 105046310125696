import Menu from "./header/menu";

export default function Template(props) {
  return (
    <>
      <div
        className="header2 header-style-01"
        style={{ marginBottom: "150px" }}
      >
        {/* <!-- support bar area end --> */}
        <nav
          className="navbar navbar-area style-01 navbar-expand-lg nav-style-02"
          // style={{ backgroundColor: "rgba(26, 92, 198,.8)" }}
        >
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo-wrapper">
                <a href="/" className="logo">
                  <img
                    src="https://files.automatapp.com/pipili/assets/images/logo.png"
                    alt=""
                  />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#bizcoxx_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <Menu />
            {/* <div className="nav-right-content style-01">
                <div className="btn-wrapper">
                  <a href="#" className="boxed-btn political-btn">
                    Donate <i className="icon-paper-plan"></i>
                  </a>
                </div>
              </div> */}
          </div>
        </nav>
        {/* <!-- navbar area end --> */}
      </div>
      {/* <!-- Header section End --> */}
    </>
  );
}
