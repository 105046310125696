export default function Template(props) {
  return (
    <div className="issues-around-us-section style-01">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-01.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Donate to Help Us Get a Professional Politic Campaign
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-02.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Are Military Coups Back in Style in Africa?
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-03.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Leadership Forum Empowers First Ladies Around the World
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-04.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Negotiating with TTP—A Different Perspective of nation
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-05.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">Donald Rumsfeld: Anti–Nation- Builder</h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-06.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Trying to Manage North Korean Instability Risks
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-07.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Policing in the Post-Floyd Era of the national security
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-08.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  The USPS Is a Public Service, Not a Business
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="news-single-items-two">
              <div className="thumbnail">
                <img src="assets/img/home-six/blog/blog-09.png" alt="" />
                <span className="tag">Politics</span>
              </div>
              <div className="content">
                <div className="post-meta">
                  <div className="author">
                    <div className="icon">
                      <img src="assets/img/home-six/icon/themeim.png" alt="" />
                    </div>
                    <p className="admin">Themeim</p>
                  </div>
                  <div className="post-date">
                    <div className="icon">
                      <img
                        src="assets/img/home-six/icon/calender-02.png"
                        alt=""
                      />
                    </div>
                    <p className="date">22 OCT, 2021</p>
                  </div>
                </div>
                <h4 className="title">
                  Biden's China Reset Is Already on the Ropes
                </h4>
                <p>
                  Fusce sodales mauris et velit accumsan vulputate.Lorem ipsum
                  dolor.
                </p>
                <div className="btn-wrapper">
                  <a href="news-single.html" className="boxed-btn event-btn">
                    <i className="fas fa-arrow-right"></i>Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9 col-12">
            <div className="blog-pagination style-01 desktop-center">
              <ul>
                <li>
                  <i className="fas fa-chevron-left"></i>
                </li>
                <li>
                  <a className="page-numbers" href="#">
                    01
                  </a>
                </li>
                <li>
                  <a className="page-numbers current" href="#">
                    02
                  </a>
                </li>
                <li>
                  <a className="page-numbers" href="#">
                    03
                  </a>
                </li>
                <li>
                  <a className="page-numbers" href="#">
                    04
                  </a>
                </li>
                <li>
                  <a className="page-numbers" href="#">
                    05
                  </a>
                </li>
                <li>
                  <a className="page-numbers" href="#">
                    <div></div>
                    <div></div>
                    <div></div>
                  </a>
                </li>
                <li>
                  <i className="fas fa-chevron-right"></i>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
