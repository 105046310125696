import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/sections/videos";
import { Hook } from "../../Services/Default";

export default class Section extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    const { hook } = this.props;

    this.fetchData(hook);
  }

  async fetchData(hook) {
    const response = await Hook.get(hook);
    const { playlistId, title = "", subtitle = "" } = response.getData();

    let items = [];

    try {
      if (playlistId) {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=100&key=AIzaSyBb8RYfRqI6R-i6HEVZpP_hLxsy5wsfa40`
        );
        const data = await response.json();
        items = data.items || [];
      }
    } catch (e) {
      //
    }

    this.setData(
      { items, title, subtitle },
      {
        cb: () => {
          this.getPage().acknowledge();
        },
      }
    );
  }
}
