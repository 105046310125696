import Api from "../../modules/Core/Helpers/Api";
import Value from "../../modules/Core/Helpers/Value";

const contentApi = Api.create("content");
const shareApi = Api.create("share");

function addParam(options, param, value) {
  options = options || {};
  const { params = {} } = options;
  params[param] = value;
  options.params = params;

  return options;
}

const ExportDefault = {
  /**
   *
   * Hook
   *
   */
  hook: function (account, repository, hook, version, options) {
    return shareApi.get(
      `content/account/${account}/repository/${repository}/hook/${hook}/${version}`,
      options
    );
  },
  hookPost: function (account, repository, hook, version, data, options) {
    options = addParam(options, "lang", Value.getValue("lang"));

    return contentApi.post(
      `account/${account}/repository/${repository}/hook/${hook}/${version}`,
      data,
      options
    );
  },
  hookAuth: function (account, repository, hook, version, options) {
    options = addParam(options, "lang", Value.getValue("lang"));

    return contentApi.get(
      `account/${account}/repository/${repository}/hook/${hook}/${version}/auth`,
      options
    );
  },
  hookPostAuth: function (account, repository, hook, version, data, options) {
    options = addParam(options, "lang", Value.getValue("lang"));

    return contentApi.post(
      `account/${account}/repository/${repository}/hook/${hook}/${version}/auth`,
      data,
      options
    );
  },
  /**
   *
   * Shared
   *
   */
  languages: function (account, repository, options) {
    return shareApi.get(
      `content/account/${account}/repository/${repository}/languages`,
      options
    );
  },
};

export default ExportDefault;
