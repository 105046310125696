export default function Template(props) {
  return (
    <footer
      className="footer-area line-bg"
      style={{ backgroundImage: "url(/assets/img/line.png)" }}
    >
      <div className="footer-top issue volunteer">
        <div className="container">
          <div className="footer-top-border padding-bottom-60 padding-top-75">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-widget widget">
                  <div className="about_us_widget wow animate__animated animate__fadeInUp">
                    <a href="index.html" className="footer-logo">
                      <img src="assets/img/footer-logo.png" alt="footer logo" />
                    </a>
                    <p>
                      President represented Delaware for 36 years in the U.S.
                      Senate before becoming the 47th Vice President of the
                      United States.
                    </p>
                    <div className="social-links">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <ul className="contact_info_list wow animate__animated animate__fadeInUp">
                  <li className="single-info-item">
                    <div className="icon style-01">
                      <i className="icon-location"></i>
                    </div>
                    <div className="details style-01">
                      66 Broklyn Street 30 Road. New York United States
                    </div>
                  </li>
                  <li className="single-info-item">
                    <div className="icon">
                      <i className="icon-envelope"></i>
                    </div>
                    <div className="details">info@yourmail.com</div>
                  </li>
                  <li className="single-info-item">
                    <div className="icon">
                      <i className="icon-phone"></i>
                    </div>
                    <div className="details">009-215-5599</div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 offset-lg-1 col-md-12">
                <div
                  className="footer-widget widget widget_subscribe subscribe-bg"
                  style={{ backgroundImage: "url(/assets/img/Mask-flag.png)" }}
                >
                  <div className="shape-01"></div>
                  <div className="shape-02"></div>
                  <div className="header-content">
                    <h4 className="title">Join Our Newsletter</h4>
                  </div>
                  <form className="subscribe-form" action="index.html">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="btn-wrapper">
                      <a href="#" className="boxed-btn btn-sanatory style-03">
                        Subscribe Now <i className="icon-paper-plan"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-border">
            <div className="row">
              <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="footer-widget widget widget_nav_menu wow animate__animated animate__fadeInUp">
                  <h4 className="widget-title">
                    Explore
                    <span className="line">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot style-02"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </span>
                  </h4>
                  <ul>
                    <li>
                      <a href="#">Our Story</a>
                    </li>
                    <li>
                      <a href="#">Team</a>
                    </li>
                    <li>
                      <a href="#">News</a>
                    </li>
                    <li>
                      <a href="#">Election</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="footer-widget widget widget_nav_menu wow animate__animated animate__fadeInUp">
                  <h4 className="widget-title">
                    Useful Links
                    <span className="line">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot style-02"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </span>
                  </h4>
                  <ul>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="#">Support</a>
                    </li>
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                <div className="footer-widget widget widget_nav_menu wow animate__animated animate__fadeInUp">
                  <h4 className="widget-title">
                    Quick Links
                    <span className="line">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot style-02"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </span>
                  </h4>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                    <li>
                      <a href="#">News</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget widget widget_nav_menu wow animate__animated animate__fadeInUp">
                  <h4 className="widget-title">
                    Events Campaigns
                    <span className="line">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot style-02"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </span>
                  </h4>
                  <div className="footer-event-item">
                    <div className="content">
                      <div className="designation">
                        <span className="event">Event</span>
                        <span className="date">29 May 2021</span>
                      </div>
                      <h6 className="title">
                        <a href="event-single.html">
                          Lets meet for protecting eco system
                        </a>
                      </h6>
                      <p>
                        Every pleasures is to welcomed pain avoided owing to the
                        duty the
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer-widget widget style-01 widget_nav_menu wow animate__animated animate__fadeInUp">
                  <div className="footer-event-item">
                    <div className="content">
                      <div className="designation">
                        <span className="event">Event</span>
                        <span className="date">29 May 2021</span>
                      </div>
                      <h6 className="title">
                        <a href="event-single.html">
                          Lets meet for protecting eco system
                        </a>
                      </h6>
                      <p>
                        Every pleasures is to welcomed pain avoided owing to the
                        duty the
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="copyright-area-inner">
                    Copyright © 2022 Senatory. All Rights Reserved. Designed by
                    Theme IM
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
