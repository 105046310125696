export default function Template(props) {
  const { component } = props;
  const timeline = component.getData("timeline", []);

  return (
    <div className="history-timeline-section">
      {/* <img src="assets/img/shape-10.png" className="history-shape-02" alt="" />
      <img
        src="assets/img/Ellipse-01.png"
        className="history-shape-03"
        alt=""
      />
      <img
        src="assets/img/Ellipse-02.png"
        className="history-shape-04"
        alt=""
      />
      <img
        src="assets/img/Ellipse-03.png"
        className="history-shape-05"
        alt=""
      /> */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title">
              <h4 className="title wow animate__animated animate__fadeInUp">
                Κοινοβουλευτιμός
              </h4>
              <p className="description wow animate__animated animate__fadeInUp">
                2019 - σήμερα
              </p>
            </div>
          </div>
        </div>
        <div className="history-item-wrap">
          {timeline.map((timeline, index) => {
            const { title, description, image, pdf, month, year } = timeline;

            return (
              <div
                key={`timelient-event-${index}`}
                className="history-single-item"
              >
                <span className="circle"></span>
                <div
                  className={`history-single-item-content wow animate__animated ${
                    index % 2 === 0
                      ? "animate__fadeInLeft"
                      : "animate__fadeInRight"
                  } `}
                  style={{ width: "500px" }}
                >
                  <div
                    className="thumb"
                    style={{
                      backgroundImage: `url(${component
                        .getApp()
                        .getFilesUrl(image)})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="content">
                    <h4 className="title">{title}</h4>
                    <p>{description}</p>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <a
                        style={{
                          display: "inline-block",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          backgroundColor: "#1a5cc6",
                          color: "#FFFFFF",
                          fontSize: "16px",
                          textAlign: "center",
                          textDecoration: "none",
                          transition: "background-color 0.3s ease",
                          cursor: "pointer",
                        }}
                        href={component.getApp().getFilesUrl(pdf)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {component.ucfirst("pdf")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="history-year wow animate__animated animate__fadeInRight">
                  <p className="month">{month}</p>
                  <h6 className="year">{year}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
