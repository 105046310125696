export default function Template(props) {
  const { component } = props;
  const { items = [], title, subtitle } = component.getData();

  return (
    <div
      className="see-our-video-section"
      style={{ marginTop: "30px", marginBottom: "50px" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title">
              {title && (
                <h4 className="title wow animate__animated animate__fadeInUp">
                  {title}
                </h4>
              )}
              {subtitle && (
                <p className="description style-03 wow animate__animated animate__fadeInUp">
                  {subtitle}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonial-carousel-five">
              {items
                .filter((item) => {
                  const { snippet = {} } = item;
                  const { thumbnails = {} } = snippet;
                  return Object.keys(thumbnails).length ? true : false;
                })
                .map((item, index) => {
                  const { snippet } = item;
                  const { thumbnails = {}, resourceId = {} } = snippet;
                  const { medium = {} } = thumbnails;
                  const { url } = medium;
                  const { videoId } = resourceId;

                  return (
                    <div
                      key={`video-${index}`}
                      className="video-bg"
                      style={{
                        backgroundImage: `url(${url})`,
                      }}
                    >
                      <div className="btn-wrapper administration">
                        <a
                          className="video-play style-03 mfp-iframe"
                          href={`https://www.youtube.com/watch?v=${videoId}`}
                        >
                          <i className="fas fa-play"></i>
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
