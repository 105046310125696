export default function Template(props) {
  const { component } = props;
  const journey = component.getData("journey", []);

  return (
    <div className="our-journey-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="history-slider">
              {/* <img
                  src="assets/img/shape-09.png"
                  className="history-shape"
                  alt=""
                /> */}
              <div className="history-slider-one">
                {journey.map(({ title, description, image }, index) => {
                  return (
                    <div key={`journey-slider-${index}`} className="thumb">
                      <div
                        className="journey-bg"
                        style={{
                          backgroundImage: `url("${component
                            .getApp()
                            .getFilesUrl(image)}")`,
                        }}
                      >
                        <div className="content">
                          <h4 className="title">{title}</h4>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="slick-slider-controls">
                <div className="slick-nav"></div>
              </div>
              <div className="history-slider-two history-number">
                {journey.map(({ year, image }, index) => {
                  return (
                    <div key={`journey-year-${index}`} className="history-year">
                      <h3>{year}</h3>
                      <img src="/assets/icon/lines.svg" alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
